export const SCHEMA_HD_V_ANNULAR = [
  {
    table_id: 'cd_v_annular',
    col_id: 'casing_id',
    col_name: 'Casing ID',
    col_desc: 'Casing ID',
    col_unit: '-',
    ui_editable: false,
    ui_show: false,
    data_type: 'str',
    decimal: 0
  },
  {
    table_id: 'cd_v_annular',
    col_id: 'mud_id',
    col_name: 'Mud ID',
    col_desc: 'Mud ID',
    col_unit: '-',
    ui_editable: false,
    ui_show: false,
    data_type: 'str',
    decimal: 0
  },
  {
    table_id: 'cd_v_annular',
    col_id: 'mud_index',
    col_name: 'Mud Index',
    col_desc: 'Mud Index',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'int',
    decimal: 0
  },
  {
    table_id: 'cd_v_annular',
    col_id: 'casing_index',
    col_name: 'Casing Index',
    col_desc: 'Casing Index',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'int',
    decimal: 0
  },
  {
    table_id: 'cd_v_annular',
    col_id: 'casing_type',
    col_name: 'Casing Type',
    col_desc: 'Casing Type',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: 0
  },
  {
    table_id: 'cd_v_annular',
    col_id: 'md',
    col_name: 'MD',
    col_desc: 'Measure Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_v_annular',
    col_id: 'z',
    col_name: 'z TVD',
    col_desc: 'true Vertical Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_v_annular',
    col_id: 'q',
    col_name: 'Flow Rate',
    col_desc: 'Flow Rate',
    col_unit: 'ppg',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_v_annular',
    col_id: 'v_ann',
    col_name: 'Annular Velocity',
    col_desc: 'Annular Velocity',
    col_unit: 'ft/s',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_v_annular',
    col_id: 'ID',
    col_name: 'Annulus ID',
    col_desc: 'Annulus ID',
    col_unit: 'in',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_v_annular',
    col_id: 'OD',
    col_name: 'Annulus OD',
    col_desc: 'Annulus OD',
    col_unit: 'in',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  }
];

export default SCHEMA_HD_V_ANNULAR;
