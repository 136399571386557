export const SCHEMA_DT_DRAG_TORQUE = [
  {
    table_id: 'cd_drag_torque',
    col_id: 'casing_id',
    col_name: 'Casing ID',
    col_desc: 'Casing ID',
    col_unit: '-',
    ui_editable: false,
    ui_show: false,
    data_type: 'str',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'casing_index',
    col_name: 'Casing Index',
    col_desc: 'Casing Index',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'int',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'casing_type',
    col_name: 'Casing Type',
    col_desc: 'Casing Type',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'pipe_type',
    col_name: 'Pipe Type',
    col_desc: 'Pipe Type',
    col_unit: '-',
    ui_editable: true,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'md',
    col_name: 'MD',
    col_desc: 'Measure Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'z',
    col_name: 'z TVD',
    col_desc: 'true Vertical Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'inclination',
    col_name: 'Inclination',
    col_desc: 'Inclination Angle',
    col_unit: 'deg',
    ui_editable: true,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'F_drag',
    col_name: 'Axial Drag Force',
    col_desc: 'Axial Drag Force',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'F_static',
    col_name: 'Axial Force Static',
    col_desc: 'Axial Force Static',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'F_trip_in',
    col_name: 'Axial Force Trip In',
    col_desc: 'Axial Force Trip In',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'F_trip_out',
    col_name: 'Axial Force Trip Out',
    col_desc: 'Axial Force Trip Out',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'F_drilling',
    col_name: 'Axial Force Drilling',
    col_desc: 'Axial Force Drilling',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'HL_static',
    col_name: 'Hook Load Static',
    col_desc: 'Hook Load Static',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'HL_trip_in',
    col_name: 'Hook Load Trip In',
    col_desc: 'Hook Load Trip In',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'HL_trip_out',
    col_name: 'Hook Load Trip Out',
    col_desc: 'Hook Load Trip Out',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'Torque',
    col_name: 'Torque',
    col_desc: 'Torque',
    col_unit: 'ft-lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'w_buckling',
    col_name: 'Wcr Buckling',
    col_desc: 'Wcr Buckling',
    col_unit: 'lb/ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  }
];

export default SCHEMA_DT_DRAG_TORQUE;
