export const SCHEMA_CD_ENVELOPE = [
  {
    table_id: 'cd_casing_envelope',
    col_id: 'casing_id',
    col_name: 'Casing ID',
    col_desc: 'Casing ID',
    col_unit: '-',
    ui_editable: false,
    ui_show: false,
    data_type: 'str',
    decimal: 0
  },
  {
    table_id: 'cd_burst_collapse',
    col_id: 'casing_index',
    col_name: 'Casing Index',
    col_desc: 'Casing Index',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'int',
    decimal: 0
  },
  {
    table_id: 'cd_burst_collapse',
    col_id: 'casing_type',
    col_name: 'Casing Type',
    col_desc: 'Casing Type',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: 0
  },
  {
    table_id: 'cd_casing_envelope',
    col_id: 'sigma_z',
    col_name: 'Sigma z',
    col_desc: 'Sigma z',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_casing_envelope',
    col_id: 'dp_burst',
    col_name: 'dp Burst',
    col_desc: 'dp Burst',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_casing_envelope',
    col_id: 'dp_collapse',
    col_name: 'dp Collapse',
    col_desc: 'dp Collapse',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  }
];

export default SCHEMA_CD_ENVELOPE;
