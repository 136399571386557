export const SCHEMA_CD_BURST_COLLAPSE = [
  {
    table_id: 'cd_burst_collapse',
    col_id: 'casing_id',
    col_name: 'Casing ID',
    col_desc: 'Casing ID',
    col_unit: '-',
    ui_editable: false,
    ui_show: false,
    data_type: 'str',
    decimal: 0
  },
  {
    table_id: 'cd_burst_collapse',
    col_id: 'casing_index',
    col_name: 'Casing Index',
    col_desc: 'Casing Index',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'int',
    decimal: 0
  },
  {
    table_id: 'cd_burst_collapse',
    col_id: 'casing_type',
    col_name: 'Casing Type',
    col_desc: 'Casing Type',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: 0
  },
  {
    table_id: 'cd_burst_collapse',
    col_id: 'md',
    col_name: 'MD',
    col_desc: 'Measure Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_burst_collapse',
    col_id: 'z',
    col_name: 'z TVD',
    col_desc: 'true Vertical Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_burst_collapse',
    col_id: 'p_burst',
    col_name: 'P Burst',
    col_desc: 'Burst Pressure Without Safety Factor',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_burst_collapse',
    col_id: 'p_collapse',
    col_name: 'P Collapse',
    col_desc: 'Collapse Pressure Without Safety Factor',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_burst_collapse',
    col_id: 'p_burst_sf',
    col_name: 'Design P Burst',
    col_desc: 'Design Burst Pressure With Safety Factor',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_burst_collapse',
    col_id: 'p_collapse_sf',
    col_name: 'Design P Collapse',
    col_desc: 'Design Collapse Pressure With Safety Factor',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_burst_collapse',
    col_id: 'f_load',
    col_name: 'F Load',
    col_desc: 'F Load',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_casing_envelope',
    col_id: 'sigma_z',
    col_name: 'Sigma z',
    col_desc: 'Sigma z',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  }
];

export default SCHEMA_CD_BURST_COLLAPSE;
