export const SCHEMA_HD_SUMMARY = [
  {
    table_id: 'cd_hydraulic_summary',
    col_id: 'casing_id',
    col_name: 'Casing ID',
    col_desc: 'Casing ID',
    col_unit: '-',
    ui_editable: false,
    ui_show: false,
    data_type: 'str',
    decimal: 0
  },
  {
    table_id: 'cd_hydraulic_summary',
    col_id: 'mud_id',
    col_name: 'Mud ID',
    col_desc: 'Mud ID',
    col_unit: '-',
    ui_editable: false,
    ui_show: false,
    data_type: 'str',
    decimal: 0
  },
  {
    table_id: 'cd_hydraulic_summary',
    col_id: 'mud_index',
    col_name: 'Mud Index',
    col_desc: 'Mud Index',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'int',
    decimal: 0
  },
  {
    table_id: 'cd_hydraulic_summary',
    col_id: 'drilled_depth',
    col_name: 'Mud MD',
    col_desc: 'Mud Measured Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_hydraulic_summary',
    col_id: 'drilled_tvd',
    col_name: 'Mud TVD',
    col_desc: 'Mud true Vertical Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_hydraulic_summary',
    col_id: 'fluid_model',
    col_name: 'Model',
    col_desc: 'Fluid Model',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_hydraulic_summary',
    col_id: 'den',
    col_name: 'MW',
    col_desc: 'Mud Weight',
    col_unit: 'ppg',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_hydraulic_summary',
    col_id: 'q',
    col_name: 'Q',
    col_desc: 'Mud Flowrate',
    col_unit: 'gpm',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_hydraulic_summary',
    col_id: 'casing_index',
    col_name: 'Casing Index',
    col_desc: 'Casing Index',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'int',
    decimal: 0
  },
  {
    table_id: 'cd_hydraulic_summary',
    col_id: 'casing_type',
    col_name: 'Casing Type',
    col_desc: 'Casing Type',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: 0
  },
  {
    table_id: 'cd_hydraulic_summary',
    col_id: 'repeated_casing',
    col_name: 'Repeated Casing',
    col_desc: 'Repeated Casing',
    col_unit: '-',
    ui_editable: false,
    ui_show: false,
    data_type: 'bool',
    decimal: ''
  },
  {
    table_id: 'cd_hydraulic_summary',
    col_id: 'ID_open_hole',
    col_name: 'Hole ID',
    col_desc: 'Hole ID',
    col_unit: 'in',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_summary',
    col_id: 'ID',
    col_name: 'Casing ID',
    col_desc: 'Casing ID',
    col_unit: 'in',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_summary',
    col_id: 'OD',
    col_name: 'Casing OD',
    col_desc: 'Casing OD',
    col_unit: 'in',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_summary',
    col_id: 'wpf',
    col_name: 'WPF',
    col_desc: 'Casing Weight per Foot',
    col_unit: 'lbm/ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_hydraulic_summary',
    col_id: 'grade',
    col_name: 'Grade',
    col_desc: 'Casing Grade',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_hydraulic_summary',
    col_id: 'top_depth_md',
    col_name: 'Top MD',
    col_desc: 'Casing Top Measured Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_hydraulic_summary',
    col_id: 'set_depth_md',
    col_name: 'Shoe MD',
    col_desc: 'Casing Shoe Measured Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_hydraulic_summary',
    col_id: 'top_depth_tvd',
    col_name: 'Top TVD',
    col_desc: 'Casing Top true Vertical Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_hydraulic_summary',
    col_id: 'set_depth_tvd',
    col_name: 'Shoe TVD',
    col_desc: 'Casing Shoe true Vertical Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_hydraulic_summary',
    col_id: 'v_kick',
    col_name: 'Kick Volume',
    col_desc: 'Kick Volume',
    col_unit: 'bbl',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 1
  },
  {
    table_id: 'cd_hydraulic_summary',
    col_id: 'h_kick',
    col_name: 'Kick Height',
    col_desc: 'Kick Height',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_hydraulic_summary',
    col_id: 'p_surf',
    col_name: 'P Surface',
    col_desc: 'Kick Surface Pressure',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  }
];

export default SCHEMA_HD_SUMMARY;
