export const SCHEMA_DT_SUMMARY = [
  {
    table_id: 'cd_dnt_summary',
    col_id: 'casing_id',
    col_name: 'Casing ID',
    col_desc: 'Casing ID',
    col_unit: '-',
    ui_editable: false,
    ui_show: false,
    data_type: 'str',
    decimal: 0
  },
  {
    table_id: 'cd_dnt_summary',
    col_id: 'casing_index',
    col_name: 'Casing Index',
    col_desc: 'Casing Index',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'int',
    decimal: 0
  },
  {
    table_id: 'cd_dnt_summary',
    col_id: 'casing_type',
    col_name: 'Casing Type',
    col_desc: 'Casing Type',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: 0
  },
  {
    table_id: 'cd_dnt_summary',
    col_id: 'WOB',
    col_name: 'WOB',
    col_desc: 'Weight on Bit',
    col_unit: 'lb',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_dnt_summary',
    col_id: 'max_WOB',
    col_name: 'Maximum WOB',
    col_desc: 'Maximum Weight on Bit',
    col_unit: 'lb',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_dnt_summary',
    col_id: 'neutral_length',
    col_name: 'Neutral Length',
    col_desc: 'Neutral Length',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_dnt_summary',
    col_id: 'neutral_md',
    col_name: 'Neutral Length MD',
    col_desc: 'Neutral Length MD',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_dnt_summary',
    col_id: 'drillcollar_length',
    col_name: 'Drillcollar Length',
    col_desc: 'Drillcollar Length',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_dnt_summary',
    col_id: 'buckling_risk',
    col_name: 'Buckling Risk',
    col_desc: 'Buckling Risk',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'bool',
    decimal: 0
  }
];

export default SCHEMA_DT_SUMMARY;
